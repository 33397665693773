export const environment = {
    production: true,
    clientName:"BJSA",
    siteUrl:"https://staging-bjs-rvhost.rivervalleytechnologies.in/",
    apiUrl: "https://staging-bjs-rvhost.rivervalleytechnologies.in/admin/backend/",
    applicationMode:"Staging",
    showAppMode:true ,
    journalName:"BJSA"

};
